import React, { useState, useEffect, useRef, FC, CSSProperties, useMemo } from "react"
import Styles from "../styles/post_search.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import GeneralButton from "../components/atoms/GeneralButton";
import Modal from "../components/Modal";
import PostItem from "../components/postItem";
import InputForm from "../components/atoms/InputForm";
import SelectBoxWithInputSearch from "../components/SelectBoxWithInputSearch";
import ActivityIndicator from "../components/atoms/ActivityIndicator";
import ConfigList from "../templates/ConfigList";
import smoothscroll from 'smoothscroll-polyfill';
import { connect } from "react-redux";
import { AppState, updateSearchResultList, updateSearchResultNextURL } from "../state/app";
import { useTeamMember } from "../hooks/useTeamMember"
import UserAvatar from "../components/UserAvatar"
import { PostTimeLineType } from "../types/models/Post";
import { getInitialStateFromSessionStorage } from "../utils/getInitialStateFromSessionStorage";
import { SelectButtonType, SelectType } from "../types/models/SelectButton";
import { ConfigType } from "../types/models/Config";
import { Tag, TagType } from "../types/models/Tag";
import client from "../apis/client";
import { CloseIcon } from "../icons/CloseIcon";
import { GearIcon } from "../icons/GearIcon";
import { useLocalSettings } from "../hooks/useLocalSettings";
import { detectDevice } from "../functions/detectDevice";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import amplitude, { logEvent } from "../Analytics";
import { Link } from "gatsby";
import { useInView } from 'react-intersection-observer';

if ( ( typeof window !== "undefined" ) && ( typeof document !== "undefined" ) ) {
    smoothscroll.polyfill();
}

const likeRangeList = [ {
    value: "all_post",
    label: "全ての投稿",
    isSelected: true
}, {
    value: 'liked_post',
    label: "いいねした投稿",
    isSelected: false
}, {
    value: 'unliked_post',
    label: "いいねしていない投稿",
    isSelected: false
} ]

//チームに所属していなければこれは無視 
const communityRangeList = [ {
    value: "all_post",
    label: "全ての投稿",
    isSelected: true
}, {
    value: 'community_post',
    label: "自チーム内の投稿",
    isSelected: false
}, {
    value: 'public_post',
    label: "他チームの投稿",
    isSelected: false
} ]

const whoseRangeList = [ {
    value: "all_post",
    label: "全ユーザー",
    isSelected: true
}, {
    value: 'others_post',
    label: "他人の投稿",
    isSelected: false
}, {
    value: 'my_post',
    label: "自分の投稿",
    isSelected: false
} ]

const sportsRangeList = [ {
    value: "all_post",
    label: "全スポーツ",
    isSelected: true
}, {
    value: 'other_sports',
    label: "フォロー外のスポーツ",
    isSelected: false
}, {
    value: 'follow_sports',
    label: "フォロー中のスポーツ",
    isSelected: false
} ]

const initialRangeList = [
    { label: "いいね!", name: "like_range", list: likeRangeList },
    { label: "投稿範囲", name: "community_range", list: communityRangeList },
    { label: "投稿者", name: "whose_range", list: whoseRangeList },
    { label: "スポーツ", name: "sports_range", list: sportsRangeList }
] as const

const rangeNameList = initialRangeList.map( range => range.name )

type rangeNameType = typeof rangeNameList[ number ]

type searchParamsType = {
    tags: string[]
    words: string
    author_pk_list: string[]
    start_date?: string
    end_date?: string
    [ key: string ]: string | string[] | undefined
}

type Props = {
    searchResultList: PostTimeLineType[],
    updateSearchResultList: ( postList: PostTimeLineType[] ) => void,
    searchResultNextURL: string,
    updateSearchResultNextURL: ( URL: string ) => void
}

const PostSearchPage: FC<Props> = ( {
    searchResultList,
    updateSearchResultList,
    searchResultNextURL,
    updateSearchResultNextURL
} ) => {

    const headerOption = {
        headerTitle: "検索",
        rightIcon: <GearIcon className={ Styles.icon } onClick={ () => setConfigModalVisible( true ) } />
    }

    const [ words, setWords ] = useState<string>( getInitialStateFromSessionStorage( "post_search_words", "" ) )
    const [ tagList, setTagList ] = useState<SelectButtonType<string>[]>( getInitialStateFromSessionStorage( "post_search_tagList", [] ) )
    const [ myTagList, setMyTagList ] = useState<SelectButtonType<string>[]>( getInitialStateFromSessionStorage( "post_search_myTagList", [] ) )
    const [ authorList, setAuthorList ] = useState<SelectButtonType<string>[]>( getInitialStateFromSessionStorage( "post_search_authorList", [] ) )
    const [ userTeam, setUserTeam ] = useState( true )
    const [ noResult, setNoResult ] = useState<boolean | null>( null )
    const [ searching, setSearching ] = useState( false )
    const [ addingMoreResult, setAddingMoreResult ] = useState( false )
    const [ configModalVisible, setConfigModalVisible ] = useState( false )
    const [ rangeList, setRangeList ] = useState<ConfigType[]>( getInitialStateFromSessionStorage( "post_search_rangeList", initialRangeList ) )
    const [ startDate, setStartDate ] = useState( getInitialStateFromSessionStorage( "post_search_start_date", null ) )
    const [ endDate, setEndDate ] = useState( getInitialStateFromSessionStorage( "post_search_end_date", null ) )
    const isLoggedIn = useIsLoggedIn()
    const [ ref, inView ] = useInView( {
        rootMargin: '120px 0px',
    } )

    useEffect( () => {
        inView && addMoreResult()
    }, [ inView ] )


    const searchResultListRef = useRef( searchResultList )
    const searchResultNextURLRef = useRef( searchResultNextURL )
    const addingMoreResultRef = useRef( addingMoreResult )
    const searchButtonRef = useRef<HTMLDivElement>( null )

    const teamMember = useTeamMember()
    const localSettings = useLocalSettings( {} )


    //sessionStorageに保存
    useEffect( () => {
        sessionStorage.setItem( `post_search_words`, JSON.stringify( words ) )
    }, [ words ] )
    useEffect( () => {
        sessionStorage.setItem( `post_search_tagList`, JSON.stringify( tagList ) )
    }, [ tagList ] )
    useEffect( () => {
        sessionStorage.setItem( `post_search_myTagList`, JSON.stringify( myTagList ) )
    }, [ myTagList ] )
    useEffect( () => {
        sessionStorage.setItem( `post_search_rangeList`, JSON.stringify( rangeList ) )
    }, [ rangeList ] )
    useEffect( () => {
        sessionStorage.setItem(
            `post_search_authorList`,
            JSON.stringify( authorList.map( member => ( {
                value: member.value,
                isSelected: member.isSelected
            } ) ) ) )
    }, [ authorList ] )
    useEffect( () => {
        sessionStorage.setItem( `post_search_start_date`, JSON.stringify( startDate ) )
    }, [ startDate ] )
    useEffect( () => {
        sessionStorage.setItem( `post_search_end_date`, JSON.stringify( endDate ) )
    }, [ endDate ] )



    useEffect( () => {
        searchResultNextURLRef.current = searchResultNextURL
    }, [ searchResultNextURL ] )

    useEffect( () => {
        searchResultListRef.current = searchResultList
    }, [ searchResultList ] )

    useEffect( () => {
        addingMoreResultRef.current = addingMoreResult
    }, [ addingMoreResult ] )

    useEffect( () => {
        const setInitialAuthorList = () => {
            const newMemberList = teamMember.map( member => ( {
                label:
                    <div style={ { display: "inline-flex", alignItems: "center" } }>
                        <UserAvatar
                            userID={ member.pk }
                            userName={ member.name }
                            profileImageURL={ member.profile_image_100 }
                            noTransition={ true }
                            style={ { height: 32, width: 32 } }
                            size={ 32 }
                            avatarSize={ 16 }
                        />
                        <span className="member_name">{ member.name }</span>
                    </div>
                ,
                value: member.pk,
                isSelected:
                    !!( authorList.find( author => author.value === member.pk ) &&
                        authorList.filter( author => author.value === member.pk )[ 0 ].isSelected )
            } ) )
            setAuthorList( newMemberList )
        }
        teamMember.length && setInitialAuthorList()
    }, [ teamMember ] )

    const onChangeText = ( name: string, value: string ) => {
        setWords( value )
    }

    const onChangeSelectButton = ( boxName: string, buttonList: SelectButtonType<string>[] ) =>
        boxName === Tag.TAG ?
            setTagList( buttonList ) :
            setMyTagList( buttonList )

    const onChangeConfigButton = ( name: string, value: string, isSelected: boolean ) => {

        const newRangeList = rangeList.map( range =>
            range.name !== name ?
                range :
                {
                    ...range,
                    list: range.list.map( r =>
                        r.value !== value ?
                            { ...r, isSelected: false } :
                            { ...r, isSelected }
                    )
                }
        ).slice()

        setRangeList( newRangeList )
    }

    const onChangeSelectMember = ( boxName: string, newMemberList: SelectButtonType<string>[] ) =>
        setAuthorList( newMemberList )

    const getTagList = async () => {
        const sessionStorageTagList = tagList
        const sessionStorageMyTagList = myTagList
        await client.get( `/api/tag/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( response => {
                const data = response.data
                const tag_list: TagType[] = data.tag_list
                const tagList = tag_list.map( tag => ( {
                    value: tag.pk,
                    label: tag.name,
                    type: tag.type,
                    isSelected:
                        sessionStorageTagList.filter( sessionStorageTag => tag.pk === sessionStorageTag.value )[ 0 ] ? //tagが一つ もしくは undefined
                            sessionStorageTagList.filter( sessionStorageTag => tag.pk === sessionStorageTag.value )[ 0 ].isSelected :
                            false
                } ) )
                const mytag_list: TagType[] = data.mytag_list
                const myTagList = mytag_list.map( tag => ( {
                    value: tag.pk,
                    label: tag.name,
                    type: tag.type,
                    isSelected:
                        sessionStorageMyTagList.filter( sessionStorageTag => tag.pk === sessionStorageTag.value )[ 0 ] ? //tagが一つ もしくは undefined
                            sessionStorageMyTagList.filter( sessionStorageTag => tag.pk === sessionStorageTag.value )[ 0 ].isSelected :
                            false
                }
                ) )
                setTagList( tagList )
                setMyTagList( myTagList )
                setUserTeam( data.team )
            } )
            .catch( error => console.error( { error } ) )
    }

    const scrollToResult = () => {
        const searchResultY = searchButtonRef.current ?
            searchButtonRef.current.getBoundingClientRect().top :
            300
        // 現在のスクロール距離
        const offsetTop = window.pageYOffset
        const buffer = 72
        const top = searchResultY + offsetTop - buffer
        const doc = document.getElementsByTagName( "body" )[ 0 ]
        const { isAndroid } = detectDevice()
        const scrollObject = isAndroid ?
            window :
            window
        scrollObject.scrollTo( {
            top,
            behavior: "smooth"
        } );
    }

    const initialSearchPost = async () => {
        const tagAllList = tagList.concat( myTagList )
        const selectedTagPKList = tagAllList.filter( tag => tag.isSelected ).map( tag => tag.value )
        const selectedAuthorList = authorList.filter( member => member.isSelected ).map( member => member.value )
        const params = {
            tags: selectedTagPKList,
            words,
            author_pk_list: selectedAuthorList,
        } as searchParamsType
        rangeList.forEach( range => {
            params[ range.name ] = range.list.filter( option => option.isSelected )[ 0 ].value
        } )
        if ( startDate && validateDate( startDate ) ) params[ "start_date" ] = startDate
        if ( endDate && validateDate( endDate ) ) params[ "end_date" ] = endDate

        setSearching( true )
        const response = await client.get( `/api/post_search/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params,
        } );
        const resultList = response.data.results;
        setNoResult( resultList.length === 0 )
        updateSearchResultList( resultList )
        setSearching( false )
        updateSearchResultNextURL( response.data.next )
        scrollToResult()
        logEvent( amplitude, "Action-Search", { mode: "Post" } )
    }

    const addMoreResult = async () => {
        if ( addingMoreResultRef.current || searching ) return
        if ( !searchResultNextURLRef.current ) return
        setAddingMoreResult( true )
        await client.get( `${ searchResultNextURLRef.current }`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            withCredentials: true,
        } )
            .then( response => {
                const results: PostTimeLineType[] = response.data.results
                const additionalResults = results.filter( post => !searchResultListRef.current.map( post => post.pk ).includes( post.pk ) )
                const newResultList = searchResultListRef.current.concat( additionalResults ).slice();
                updateSearchResultList( newResultList )
                updateSearchResultNextURL( response.data.next )
                setAddingMoreResult( false )
            } )
            .catch( error => console.warn( error ) );
    }

    const renderConfig = () =>
        <ConfigList
            configList={ rangeList }
            title="検索範囲の設定"
            onChangeConfigButton={ onChangeConfigButton } />


    const ResultMemo = useMemo( () =>
        searchResultListRef.current.map( post =>
            <PostItem key={ post.pk } post={ post } showUserName={ localSettings.showUserNameOnTimeline } />
        ), [ searchResultListRef.current, noResult ] )

    useEffect( () => {
        if ( !isLoggedIn ) return
        getTagList()
        const scroll = sessionStorage.getItem( "postSearchScroll" )
        document.documentElement.scrollTop = scroll ? JSON.parse( scroll ) : 0
        return () => sessionStorage.setItem( "postSearchScroll", JSON.stringify( document.documentElement.scrollTop ) )
    }, [ isLoggedIn ] )

    const validateDate = ( textDate: string ) => {
        try {
            const yearMonthDayArray = textDate.split( "-" ).map( n => Number( n ) )
            if ( yearMonthDayArray.length !== 3 ) return false
            if ( !yearMonthDayArray.every( n => n !== NaN ) ) return false
            let [ year, month, day ] = yearMonthDayArray
            if ( year < 2019 ) return false
            if ( month < 1 || 12 < month ) return false
            if ( day < 1 || 31 < day ) return false
            const monthStr = ( "0" + month ).slice( -2 )
            const dayStr = ( "0" + day ).slice( -2 )
            const dateStr: string = `${ String( year ) }-${ monthStr }-${ dayStr }`
            const date: any = new Date( dateStr )
            if ( isNaN( date ) ) return false
            return `${ String( year ) }-${ monthStr }-${ dayStr }`

        }
        catch ( e ) {
            console.log( e )
            return false
        }
    }

    const conditionBoxStyle: CSSProperties = {
        position: 'relative',
        borderBottom: "1px solid rgba(0,0,0,0.12)",
        marginBottom: 24,
        backgroundColor: "rgba(0,0,0,0)"
    }

    const labelStyle: CSSProperties = {
        position: 'absolute',
        top: -12,
        left: 12,
        zIndex: 2,
        padding: 4,
        // background: "linear-gradient( rgba(255,255,255,0) 0% , #fff 55% )",
        width: "unset",
    }

    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="PostSearch" />
                <div className={ Styles.container }>
                    <div style={ { padding: 8, backgroundColor: "#fff" } }>
                        <Modal visible={ configModalVisible } closeModal={ () => setConfigModalVisible( false ) }>
                            { renderConfig() }
                        </Modal>
                        <div className={ Styles.icon_wrapper }>
                            <GearIcon className={ Styles.icon } onClick={ () => setConfigModalVisible( true ) } />
                        </div>
                        <div style={ { textAlign: "right", marginBottom: 8 } }>
                            <Link to="/comment_search">コメントの検索はこちら</Link>
                        </div>
                        <InputForm
                            style={ { marginBottom: 16, padding: "16px 12px", ...conditionBoxStyle } }
                            label="検索ワード"
                            labelStyle={ labelStyle }
                            name="words"
                            type="text"
                            value={ words }
                            onKeyPress={ ( e: React.KeyboardEvent<HTMLInputElement> ) => {
                                if ( e.key !== 'Enter' ) return
                                e.preventDefault()
                                initialSearchPost()
                            } }
                            onChange={ onChangeText }
                            inputFormStyle={ {
                                display: "inherit",
                                margin: "auto",
                                marginRight: 0,
                                width: "95%",
                                borderRadius: 16,
                                padding: "8px 16px",
                                backgroundColor: "#fafafa"
                            } }
                            placeholder="半(全)角スペース区切りでAND検索" />
                        { userTeam && <SelectBoxWithInputSearch
                            key="-1"
                            style={ conditionBoxStyle }
                            label="投稿者"
                            labelStyle={ labelStyle }
                            buttonLabel="チームメンバー"
                            buttonList={ authorList }
                            onChangeSelectButton={ onChangeSelectMember }
                            boxName="teamMember"
                            type={ SelectType.CHECKBOX }
                            placeholder="メンバーを検索" /> }
                        { userTeam && <SelectBoxWithInputSearch
                            key="0"
                            style={ conditionBoxStyle }
                            label="チームタグ"
                            labelStyle={ labelStyle }
                            buttonList={ tagList }
                            onChangeSelectButton={ onChangeSelectButton }
                            boxName={ Tag.TAG }
                            type={ SelectType.CHECKBOX }
                            placeholder="チームタグを検索" /> }
                        { localSettings.isMyTagActive &&
                            <SelectBoxWithInputSearch
                                key="1"
                                style={ ( conditionBoxStyle ) }
                                label="Myタグ"
                                labelStyle={ labelStyle }
                                buttonList={ myTagList }
                                onChangeSelectButton={ onChangeSelectButton }
                                boxName={ Tag.MYTAG }
                                type={ SelectType.CHECKBOX }
                                placeholder="Myタグを検索" /> }
                        <div className={ Styles.condition_container } style={ { ...conditionBoxStyle, borderBottom: "none" } }>
                            <div style={ { padding: "12px 0" } }>
                                <label className={ Styles.condition_label } style={ labelStyle }>期間指定</label>
                                <div className={ Styles.range_wrapper }>
                                    <div className={ Styles.date_wrapper }>
                                        <div className={ Styles.date_container }>
                                            <input type="date" name="start_date" value={ startDate } onChange={ ( e ) => setStartDate( e.target.value ) } placeholder="2020-1-1" />
                                            <CloseIcon className={ Styles.date_clear_icon } onClick={ () => setStartDate( "" ) } />
                                            <span style={ { marginLeft: 8 } }>から</span>
                                        </div>
                                        { ( startDate && !validateDate( startDate ) ) &&
                                            <p className={ Styles.invalid_date }>無効な日付です</p>
                                        }
                                    </div>
                                    {/* <span style={ { transform: "translate3d(0, 8px, 0)", margin: "0 8px", fontWeight: "bold" } }> 〜 </span> */ }
                                    <div className={ Styles.date_wrapper }>
                                        <div className={ Styles.date_container }>
                                            <input type="date" name="end_date" value={ endDate } onChange={ ( e ) => setEndDate( e.target.value ) } placeholder="2020-1-1" />
                                            <CloseIcon className={ Styles.date_clear_icon } onClick={ () => setEndDate( "" ) } />
                                            <span style={ { marginLeft: 8 } }>まで</span>
                                        </div>
                                        { ( endDate && !validateDate( endDate ) ) &&
                                            <p className={ Styles.invalid_date }>無効な日付です</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={ Styles.submit_button_container } ref={ searchButtonRef }>
                            <GeneralButton
                                title="検索"
                                onClick={ initialSearchPost }
                                loading={ searching }
                                style={ { padding: "8px 48px", width: "100%", maxWidth: "450px", margin: "auto" } } />
                        </div>
                    </div>
                    <div className={ Styles.result_container }>
                        { ResultMemo }
                        <div id="observed" ref={ ref }></div>
                        { noResult &&
                            <p style={ { textAlign: "center", width: "100%", fontWeight: "bold", marginBottom: "48px" } }>該当する投稿はありませんでした</p>
                        }
                        { addingMoreResult &&
                            <ActivityIndicator /> }
                    </div>
                </div>
            </>
        </Layout>
    );
}

const mapStateToProps = ( state: AppState ) => ( {
    searchResultList: state.app.searchResultList,
    searchResultNextURL: state.app.searchResultNextURL
} )

const mapDispatchToProps = {
    updateSearchResultList,
    updateSearchResultNextURL
}




export default connect(
    mapStateToProps,
    mapDispatchToProps
)( PostSearchPage )
